import React from "react"
import Layout from "../components/layout"

export default function ContentPage({ title, children }) {
    return (
        <Layout pageTitle={title}>
            <div className="content-page">
                <div className="h1 page-header">
                    <h1>{title}</h1>
                </div>
                <div className="row">
                    <div className="page-body col col-12 col-md-9">
                        {children}
                    </div>
                    <aside className="col col-12 col-md-3">
                        <div className="vr d-none d-md-block"></div>
                        <hr className="d-block d-md-none"/>
                        <h2>Contact</h2>
                        Grant Kniffen<br/>
                        4 Parrish Ct.<br/>
                        Dardenne Prairie, MO 63368<br/>
                        <a href="tel:+13146028374">314.602.8374</a><br/>
                        <a href="mailto:kniffeng@yahoo.com">kniffeng@yahoo.com</a>
                    </aside>
                </div>

            </div>
        </Layout>
    )
}
