import React from "react"
import ContentPage from "../components/content-page"
import {graphql} from 'gatsby'
import MarkdownIt from 'markdown-it'
import SEO from "../components/seo";


export default function Page({ data }) {
    const page = data.wagtail.page;

    function createMarkup() {
        const md = new MarkdownIt();
        return {__html: md.render(page.body)};
    }

    return (
        <ContentPage >
            <SEO title={page.title} />
            <h1>{page.title}</h1>
            <div dangerouslySetInnerHTML={createMarkup()}></div>
        </ContentPage>

    )
}

export const query = graphql`
    query($slug: String!) {
        wagtail {
            page(slug: $slug) {
                title
                pageType
                ... on BasicPage {
                    body
                }
            }
        }
    }
`;
